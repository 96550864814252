.unregister__modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2010;
  .unregister__modal {
    position: relative;
    margin: 50vh auto;
    transform: translateY(-50%);
    width: 880px;
    height: 776px;
    padding: 80px 70px 20px;
    box-sizing: border-box;
    border-radius: 60px;
    background-color: #fff;

    .unregister__modal_title {
      font: 500 50px/66px 'MI Lan Pro';
      color: #000000;
      text-align: center;
    }
    .unregister__modal_content {
      font: 48px/70px 'MI Lan Pro';
      color: rgba(0, 0, 0, 0.8);
    }
    .unregister__modal_btn-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 80px;
    }
  }
}
