html,
body,
#root {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  a,
  input,
  div,
  button,
  a:focus,
  input:focus,
  div:focus,
  button:focus {
    outline: none;
    -webkit-focus-ring-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
html {
  height: 100%;
}

button {
  border: 0;
  padding: 0;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: #000;
}

.purchase + .footer,
.book__detail + .footer {
  position: relative;
}

.global {
  height: 100%;
}

/**
 * 多行文本溢出
 * overflow
 */
@mixin text-lines($lineNum) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineNum;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

/**
 * 单行文本溢出
 * overflow
 */
@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
