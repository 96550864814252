.stars {
  display: inline-block;
  margin-left: 17px;
  div {
    display: inline-block;
    margin-right: 1px;
    width: 32px;
    height: 32px;
  }
  .full-star {
    background: url(../../assets/full_star.png) no-repeat;
    background-size: 100% 100%;
  }
  .half-star {
    background: url(../../assets/half_star.png) no-repeat;
    background-size: 100% 100%;
  }
  .empty-star {
    background: url(../../assets/empty_star.png) no-repeat;
    background-size: 100% 100%;
  }
}
