.unregister-res {
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 300px 80px 0;
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
    .title {
      margin: 60px 0 30px;
      font: 500 52px/69px 'MI Lan Pro';
      color: #000000;
    }
    .context {
      font: 400 42px/58px 'MI Lan Pro';
      color: rgba(0, 0, 0, 0.9);
    }
  }
}
