.unregister-nav {
  position: relative;
  width: 100%;
  height: 130px;
  padding: 36px 0 33px;
  box-sizing: border-box;
  border-top: 1px solid #e3e5e4;
  border-bottom: 1px solid #e3e5e4;

  .unregister-nav_title {
    width: 100%;
    font: 46px/61px MiSans;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
  }

  img {
    position: absolute;
    left: 60px;
    width: 27px;
    height: 49px;
  }
}
