.divided-line {
  width: 100%;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    margin: 0 70px;
    width: 100%;
    height: 2px;
    background: #000000;
    opacity: 0.1;
  }
}
