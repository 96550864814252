@import '../../styles/common.scss';

.bought__list {
  width: 100%;
  background-color: #fff;
  .bought__list_body {
    padding: 190px 50px 0 50px;
    .bought__list_content {
      .book__item {
        display: flex;
        height: 262px;
        margin-bottom: 64px;
        .book__item_img {
          margin-right: 40px;
          width: 180px;
          height: 262px;
        }
        .book__item_img img {
          width: 180px;
          height: 262px;
          border-radius: 16px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .book__item_desc {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          div {
            width: 100%;
          }
          .book-title {
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 46px;
            line-height: 61px;
            color: #191919;
            word-wrap: break-word;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .book__item_desc_sub_title,
          .book__item_desc_summary {
            width: 100%;
            font-size: 36px;
            line-height: 48px;
            color: #999;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .book__item_desc_summary {
            margin-top: 8px;
          }
        }
        .book__item_button {
          display: flex;
          align-items: center;
          margin-left: 36px;
          .button-area {
            width: 216px;
            height: 84px;
            color: #ff8140;
            background-color: #fff;
            font-weight: 500;
            font-size: 36px;
            line-height: 36px;
            border: 2px solid #ff8140;
            border-radius: 100px;
          }
        }
      }
    }
  }
}
