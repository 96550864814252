@import '../../styles/common.scss';

@mixin sugCommonStyle {
  padding: 0;
  list-style: none;
  width: 980px;
  margin: 50px auto 0;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 30px;
}
.search_container-wrap {
  z-index: 29;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  .search_container {
    z-index: 30;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(204, 204, 204, 0.2);
    // 主体内容
    .search_content {
      margin: 150px auto;
      text-align: center;

      // 搜索框
      .search_input {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 48px auto 0;
        width: 980px;
        height: 104px;
        .search_input_icon {
          position: absolute;
          top: 22px;
          left: 34px;
          width: 60px;
          height: 60px;
          background: url(../../assets/search_input.png) no-repeat;
          background-size: 100%;
        }
        .search_input_text {
          margin: 0;
          width: 850px;
          height: 100%;
          padding-left: 104px;
          box-sizing: border-box;
          outline: none;
          border: 2px solid #999999;
          border-radius: 100px;
          font-size: 40px;
          caret-color: #ff8640;
          &::placeholder {
            color: #666;
          }
        }
        // 取消
        .search_close {
          font: 46px/61px 'MiSans';
          color: #666666;
        }
      }

      // sug词条
      .search_sug {
        @include sugCommonStyle();
        padding: 4px 56px;
        text-align: left;
        li {
          margin: 56px 0;
          color: rgba(0, 0, 0, 0.4);
          font: 42px/56px 'MI Lan Pro';
          @include text-overflow();
          .search_sug_highlight {
            color: #ff8640;
          }
        }
      }
      // 搜索词条
      .search_result {
        @include sugCommonStyle();
        max-height: 1700px;
        padding: 4px 56px;
        overflow-y: auto;
        text-align: left;
        border-radius: 40px;

        &::-webkit-scrollbar {
          width: 5px;
          height: 13px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.5);
          background-clip: padding-box;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          min-height: 28px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }

        .search_result_item {
          margin: 56px 0;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          .search_result_item_img {
            img {
              width: 180px;
              height: 262px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              box-sizing: border-box;
              border-radius: 20px;
            }
          }
          .search_result_item_info {
            margin-left: 36px;
            width: 652px;
            .search_result_item_info_title {
              @include text-overflow();
              font: 500 44px/58px 'MiSans';
            }
            .search_result_item_info_desc,
            .search_result_item_info_author,
            .search_result_item_info_price {
              font: 36px/48px 'MiSans';
              color: rgba(0, 0, 0, 0.4);
            }
            .search_result_item_info_desc {
              margin: 30px 0 10px;
              @include text-lines(2);
            }
            .author_price-dot {
              display: inline-block;
              margin: 30px 20px 6px;
              width: 6px;
              height: 6px;
              background-color: #b3b3b3;
              border-radius: 50%;
            }
          }
        }
      }
      // 搜索词条
      .search_result_empty {
        @include sugCommonStyle();
        height: 400px;
        font-size: 40px;
        line-height: 53px;
        // 搜索无结果
        .search_result_none {
          text-align: center;
          padding: 174px 0;
          color: #b3b3b3;
        }
      }
    }
  }
}
