.home {
  .nav__home_header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 138px;
    padding-top: 15px;
    img {
      float: right;
      margin-right: 40px;
      width: 100px;
      height: 100px;
    }
  }
  .nav__home_content {
    margin: 0 auto;
    margin-top: 270px;
    .nav__home_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      .nav__home_logo_img {
        width: 76px;
        height: 76px;
        background: url(../assets/logo.png) no-repeat;
        background-size: 100%;
      }
      .nav__home_logo_text {
        margin-bottom: 1px;
        margin-left: 26px;
        font: bold 52px/73px Songti SC;
      }
    }
    .nav__home_input {
      width: 980px;
      height: 104px;
      margin: 90px auto 0;
      position: relative;
      .nav__home_input_icon {
        position: absolute;
        top: 22px;
        left: 34px;
        width: 60px;
        height: 60px;
        background: url(../assets/search_input.png) no-repeat;
        background-size: 100%;
      }
      .nav__home_input_text {
        outline: none;
        border: 2px solid #999999;
        margin: 0;
        width: 100%;
        height: 100%;
        padding-left: 104px;
        box-sizing: border-box;
        border-radius: 100px;
        font: 40px/53px MiSans;
        color: #999999;
        caret-color: #ff8640;
        &::placeholder {
          color: #666;
        }
      }
    }
  }
}
