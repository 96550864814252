.nav__bar {
  position: fixed;
  z-index: 20;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 15px 40px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e3e5e4;

  img {
    width: 100px;
    height: 100px;
  }

  img.user {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.14);
  }
  .tab_info {
    display: flex;
    align-items: center;
    .tab_name {
      margin-left: 20px;
      font: 46px/61px 'MiSans';
      color: rgba(0, 0, 0, 0.9);
      mix-blend-mode: normal;
    }
  }
  .only-icon {
    .search {
      display: none;
    }
    img {
      position: absolute;
      right: 40px;
    }
  }
  .search {
    margin-right: 40px;
  }

  .nav__bar_account_bar-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 19;
    .nav__bar_account_bar {
      position: absolute;
      z-index: 20;
      top: 130px;
      right: 50px;
      box-sizing: border-box;
      width: 500px;
      height: 356px;
      padding: 30px 80px;
      list-style: none;
      font: 500 44px/58px 'MI Lan Pro';
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 19.2px 43.2px rgba(0, 0, 0, 0.102), 0px 2.6px 10.8px rgba(0, 0, 0, 0.034);
      border-radius: 48px;
      li {
        padding: 45px 0;
      }
    }
  }
}
