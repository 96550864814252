.ticketPay__modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  .ticketPay__modal {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 666px;
    border-radius: 75px 75px 0 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 91px 80px 124px 80px;
    .ticketPay__modal_close_icon {
      position: absolute;
      top: 52.44px;
      right: 51.77px;
      width: 39.78px;
      height: 39.49px;
      background-image: url(../../assets/modal_close_icon.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .ticketPay__modal_icon {
      width: 100%;
      height: 120px;
      margin-bottom: 37px;
      display: flex;
      justify-content: center;
      img {
        width: 120px;
        height: 120px;
      }
    }

    .ticketPay__modal_content {
      font-size: 46px;
      line-height: 46px;
      color: #000000;
      text-align: center;
    }
    .ticketPay__modal_btn-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 108px;
      width: 100%;
      height: 140px;
      .ticketPay__modal_btn_pay,
      .ticketPay__modal_btn_exchange {
        box-sizing: border-box;
        padding: 38px 40px;
        width: 440px;
        font-weight: 500;
        font-size: 48px;
        line-height: 64px;
        border-radius: 100px;
      }
      .ticketPay__modal_btn_pay {
        background: rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.8);
      }
      .ticketPay__modal_btn_exchange {
        background: #ff8640;
        color: #ffffff;
      }
    }
  }
}
