.privacy__modal_outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2010;
  display: flex;
  justify-content: center;
  align-items: center;
  .privacy__modal {
    box-sizing: border-box;
    width: 880px;
    // height: 776px;
    min-height: 556px;
    padding: 80px;
    border-radius: 60px;
    background-color: #ffffff;
    position: relative;
    .privacy__modal_title {
      color: #000000;
      text-align: center;
      height: 66px;
      font-size: 50px;
      line-height: 66px;
      font-weight: 500;
      margin-bottom: 60px;
    }
    // 隐私
    .privacy__modal_info {
      box-sizing: border-box;
      padding-right: 20px;
      min-height: 70px;
      overflow: scroll;
      height: 870px;
      font-size: 48px;
      line-height: 70px;
      word-wrap: break-word;
      color: rgba(0, 0, 0, 0.8);
      text-align: justify;
      margin-bottom: 50px;
      .privacy__modal_info_first {
        margin-top: 0;
      }
      a {
        color: #0d84ef;
      }
    }
    .privacy__modal_btn {
      display: flex;
      justify-content: space-between;
      .privacy__modal_btn_agree,
      .privacy__modal_btn_disagree {
        width: 340px;
        height: 130px;
        font-size: 42px;
        font-weight: 500;
        line-height: 130px;
        text-align: center;
        border-radius: 70px;
      }
      .privacy__modal_btn_agree {
        background-color: #f0f0f0;
        color: rgba(0, 0, 0, 0.8);
      }
      .privacy__modal_btn_disagree {
        background-color: #ff8640;
        color: #ffffff;
      }
    }
  }
}
