@import '../../styles/common.scss';

.book__detail {
  height: 100vh;
  overflow-y: auto;
  // 主体
  .book__detail_content {
    margin: 0px auto;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: rgba(204, 204, 204, 0.2);

    // 标题等头部
    .book__detail_content_header {
      padding: 213px 50px 40px;
      background-color: #fff;
      .cover-info {
        display: flex;
        img {
          width: 282px;
          height: 410px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 26px;
        }

        .book__detail_content_header_info {
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 48px;

          // 书籍描述
          .book__detail_content_header_desc {
            .book__detail_content_header_title {
              .book_title {
                margin: 9px 0 0;
                font: 500 60px/80px MiSans;
                color: #1f2126;
              }
              .book_author {
                margin-top: 20px;
                margin-bottom: 40px;
                font-size: 38px;
                line-height: 50px;
                color: #808080;
              }
              .book_price {
                margin-top: 40px;
                font-size: 20px;
                line-height: 27px;
                font: bold 46px/54px 'DIN Alternate';
                color: #f54949;
              }
            }
          }
        }
      }

      .book__detail_content_info {
        // 简介
        .book_intro {
          position: relative;
          margin-bottom: 22px;
          width: 100%;
          font-size: 42px;
          line-height: 70px;
          color: #626366;
        }
        .book_intro_fold {
          @include text-lines(3);
        }
        // 版权
        .book_right {
          margin-bottom: 10px;
          font-size: 34px;
          line-height: 45px;
          color: #abaeb3;
        }

        // 按钮
        .book__detail_content_header_button,
        .read_button {
          display: flex;
          margin: 50px 0;
          div {
            width: 460px;
            height: 120px;
            background-color: #f5f5f5;
            border-radius: 60px;
            text-align: center;
            line-height: 56px;
            &:nth-child(2) {
              margin-left: 60px;
              background-color: #ff8640;
            }
          }
        }
        // 立即阅读按钮
        .read_button {
          justify-content: center;
          div {
            width: 860px;
            height: 120px;
            background-color: #ff8640;
          }
        }
      }
    }
    .book__detail_content-empty {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 36px;
      line-height: 48px;
      color: #a2a5a8;
      text-align: center;
      img {
        width: 600px;
        height: 328px;
        margin-bottom: 60px;
      }
    }
    // 无精彩评论
    .book__detail_content-empty + .book__detail_content_comment {
      display: none;
    }
    // 评价
    .book__detail_content_comment {
      margin-top: 20px;
      padding: 50px;
      background-color: #fff;
      .book__detail_content_comment_title {
        font: 500 50px/66px 'MiSans';
        color: #1f2126;
      }

      .book__detail_content_comment_list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-left: 0;
        .book__detail_content_comment_item {
          display: flex;
          padding: 20px 30px;

          margin-bottom: 30px;
          .book__detail_content_comment_item_cover {
            margin-right: 10px;
            flex-shrink: 0;
            width: 94px;
            height: 94px;

            img {
              width: 94px;
              height: 94px;
              border-radius: 50%;
            }
          }
          .book__detail_content_comment_item_content {
            position: relative;
            flex: 1;
            .book__detail_content_comment_item_header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              color: #abaeb3;
              font-size: 36px;
              line-height: 48px;
              .comment_item_nick .nick_name {
                display: inline-block;
                max-width: 390px;
                @include text-overflow();
                vertical-align: text-bottom;
              }
              .comment_item_time {
                flex: 1;
                text-align: end;
              }
              .comment_item_rate {
                margin-left: 10px;
              }
            }
            .comment_item_content {
              font-size: 46px;
              line-height: 76px;
              color: #1f2126;
              white-space: pre-wrap;
            }
            .comment_item_fold {
              @include text-lines(4);
            }
            .vertical-line {
              margin-top: 60px;
              width: 100%;
              height: 1px;
              background-color: #dfe2e6;
            }
          }

          &:last-child .vertical-line {
            display: none;
          }

          &:last-child .comment_item_fold + .more {
            bottom: 10px;
          }
        }
      }

      .book__detail_content_comment-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 160px 0 600px;
        font-size: 36px;
        line-height: 48px;
        color: #a2a5a8;
        text-align: center;
        img {
          width: 600px;
          height: 328px;
          margin-bottom: 60px;
        }
      }
    }

    .book_intro_fold + .more,
    .comment_item_fold + .more {
      position: absolute;
      display: block;
      bottom: 66px;
      right: 25px;

      .more-shadow {
        display: inline-block;
        width: 80px;
        height: 60px;
        vertical-align: bottom;
        background: linear-gradient(270deg, #ffffff 21.25%, rgba(255, 255, 255, 0) 100%);
      }
      .more-text {
        display: inline-block;
        background-color: #fff;
        text-align: right;
        text-decoration-line: underline;
        font-size: 38px;
        line-height: 70px;
        color: #abaeb3;
      }
    }
    .more {
      display: none;
    }
    .book_intro_fold + .more {
      bottom: 0;
      right: 5px;
    }
  }

  .book__detail_loading {
    width: 100%;
    height: 100%;
    background: url('../../assets/loading.png') no-repeat;
    background-size: 100% 100%;
  }
}
