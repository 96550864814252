.toast {
  position: fixed;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  padding: 36px 45px;
  box-sizing: border-box;

  background: linear-gradient(0deg, rgba(31, 31, 31, 0.75), rgba(31, 31, 31, 0.75)),
    rgba(92, 92, 92, 0.5);
  background-blend-mode: normal, color-burn;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(180px);
  border-radius: 36px;

  font-size: 36px;
  line-height: 48px;
  color: rgba(255, 255, 255, 0.8);

  animation: show 3s ease-in-out 1;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
