@import '../../styles/common.scss';

.unregister_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .unregister_container_header {
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .unregister_container_header_logo {
      margin-bottom: 50px;
      width: 160px;
      height: 160px;
      background: url(../../assets/logo.png) no-repeat;
      background-size: 100% 100%;
    }
    .unregister_container_header_title {
      font-weight: 700;
      font-size: 46px;
      line-height: 61px;
      color: #000000;
      text-align: center;
    }
    img {
      width: 97px;
      height: 97px;
    }
  }

  .unregister_container_content {
    padding: 0 60px;
    color: #000000;

    p {
      font: 500 38px/50px 'MI Lan Pro';
    }

    .unregister_container_content_box {
      margin-top: 40px;
      padding-bottom: 10px;

      .unregister_container_content_title {
        font: 500 42px/56px 'MI Lan Pro';
      }
      .unregister_container_content_overview {
        font: 500 38px/50px 'MI Lan Pro';
        color: #827878;
      }
    }
  }

  .unregister_container_bottom {
    position: fixed;
    bottom: 20px;
    padding: 0 80px;
    .tip {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
      img {
        margin-right: 20px;
        width: 50px;
        height: 50px;
      }
      .tip-text {
        font-size: 36px;
        line-height: 59px;
        color: rgba(0, 0, 0, 0.4);

        a {
          color: #0d84ff;
          text-decoration: underline;
        }
      }
    }
    .tip-confirm {
      align-items: center;
    }
    .register-btn {
      .btn {
        width: 920px;
        height: 140px;
        margin-bottom: 36px;
        text-align: 'center';
        color: '#FFFFFF';
        font: 500 48px/64px 'MI Lan Pro';
        border-radius: 70px;
      }
      .quit-register {
        background-color: #ff8640;
      }
      .confirm-register {
        background-color: #f0f0f0;
        color: #333333;
      }
      .back {
        color: #333333;
      }
    }
  }
}
