.login__modal_outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2010;
  display: flex;
  justify-content: center;
  align-items: center;
  .login__modal {
    box-sizing: border-box;
    height: 588px;
    width: 880px;
    padding: 70px 70px 80px 70px;
    border-radius: 60px;
    background-color: #fff;
    .login__modal_title {
      color: #000000;
      text-align: center;
      font-size: 50px;
      font-weight: 500;
      line-height: 66px;
      height: 66px;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .login__modal_info {
      font-size: 12px;
      color: #999;
      text-align: center;
      margin-bottom: 39px;
    }
    .login__modal_button {
      width: 100%;
      height: 140px;
      background-color: #ff8640;
      color: #fffbfb;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // 隐私
    .login__modal_privacy {
      margin-top: 63px;
      position: relative;
      display: flex;
      // 未登录的提示toast
      .login__modal_privacy_toast {
        top: -142px;
        position: absolute;
        height: 128px;
        width: 414px;
        background: url(../../assets/privacy_toast.png) no-repeat;
        background-color: transparent;
        background-size: 100%;
        text-align: center;
        line-height: 110px;
        font-size: 38px;
        color: rgba(255, 255, 255, 0.8);
      }
      .login__modal_privacy_box {
        flex-shrink: 0;
        display: block;
        margin-right: 18px;
        width: 56px;
        height: 56px;
        background: url(../../assets/unchoose.png) no-repeat;
        background-size: 100% 100%;
      }
      a {
        color: #0e84fe;
      }
      .login__modal_privacy_info {
        height: 112px;
        font-size: 38px;
        line-height: 56px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
